import { useTranslation } from "react-i18next";

type PathProps = {
  path: string;
};

export const Translator = ({ path }: PathProps) => {
  const { t } = useTranslation();

  return <>{t(path)}</>;
};
