import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // max-width: 440px;
  // width: 100%;
  width: 440px;
  @media (max-width: 500px) {
    width: 95vw;
    height: 100%;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // max-width: 440px;
  // width: 100%;
  width: 440px;
  padding-top: 24px;
  z-index: 1;
  @media (max-width: 500px) {
    width: 90%;
  }
`;