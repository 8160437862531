import axios from "axios";


const username = process.env.REACT_APP_AUTH_API_USER
const password = process.env.REACT_APP_AUTH_API_PASS

// TODO: rever o btoa() deprecated
const token = btoa(`${username}:${password}`, 'utf8')

const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_URL_LOGIN_AUTH_API,
  headers: {
    Authorization: `Basic ${token}`,    
    "Content-Type": "application/json;charset=UTF-8"                

  }
});

export default apiAuth;