import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    height: 100vh;
    background: #fff;
    font-size: 16px;
    color: #000;
    font-smooth: always;
    font-weight: 200;
    font-family: "Raleway", Helvetica;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden
  }

  input, button, label, div {

    font-family: "Raleway", Helvetica;
  }

  body > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: rgb(230, 0, 0);
  }
`
