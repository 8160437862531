import { useForm } from "react-hook-form"
import React, { useEffect, useRef, useState } from "react"
import { HTTP_STATUS } from "../../common/httpStatus"
import apiAuth from "../../services/api"
import axios, { AxiosError } from "axios"
import { getCookie, setCookie, deleteCookie } from "../../common/cookies"
import { Container, Form } from "./style"
import { Input, Translator } from "../../components"
import { Box, Button, IconButton, Stack, Text, Tooltip } from "@chakra-ui/react"



import iconLogin from "../../assets/images/icon-login-user.svg"
import iconEye from "../../assets/images/icon-eye.svg"
import iconEyeClosed from "../../assets/images/icon-eyeclosed.svg"
import iconPassword from "../../assets/images/icon-password.svg"

import { useTranslation } from "react-i18next"
import LanguageSelector, { languageValueConverter } from "../../components/languageSelector"

import { products } from "../../components/visualIdentity/visualIdentity"
import { loginSubmit, link } from "./login-submit"
import { ResetForm } from "./ResetForm"
import RecoverPassword from "../../components/modal/recoverPassword"
import { log } from "console"
import HandTalk from "../../components/handTalk"

/*
  TODO: Verificar como ficará o redirect padrão e o condicional, 
  como o token será salvo (connect salva como cookie e pesquisa 
  salva como localStorage) e o padrão usado pra gerar o token. 
  Verificar o Encoding no redirect condicional.
  parametrizar o ícone.
*/

export const Login = () => {
  const [active, setIsActive] = useState(true)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isErrorVisible, setErrorVisible] = useState(false)
  const [isMessageVisible, setMessage] = useState(false)
  const [isFirstAccess, setIsFirstAccess] = useState(false)
  const [loginMode, setLoginMode] = useState("")
  const [loginTips, setLoginTips] = useState("")
  const [passwordTips, setPasswordTips] = useState("")
  const [helpTips, setHelpTips] = useState("")
  const [isLoginByEmail, setIsLoginByEmail] = useState(false)
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [enableHandTalk, setEnableHandTalk] = useState(true);

  const V2Logged = useRef(false);
  const { t } = useTranslation()

  interface PasswordInfo {
    id: null;
    title: string;
    raw: string;
    language: string;
  }
  
  interface LanguageItem {
    password: PasswordInfo;
    language: string;
    languageTitle: string;
    login: { id: null; title: string; raw: string; language: string };
    tips: { id: null; title: string; raw: string; language: string };
  }

  const {
    register,
    formState: { errors },
  } = useForm()
  const [languagesData, setLanguagesData] = useState([])

  const origins = JSON.parse(process.env.REACT_APP_ORIGIN as string)
  const params = new URLSearchParams(window ? window.location.search : {})
  let urlRedirect = params.get("to")
    ? params.get("to")
    : process.env.REACT_APP_HOME

  // FIXME: REMOVER ESTE TRECHO QUANDO ESSA PESQUISA TIVER FINALIZADO
  if (urlRedirect && window.location.search.includes('lear-termd&i24')) {
    urlRedirect = new URL(urlRedirect).origin + "/lear-termd&i24";
  }

  function redirectTo(url?: string | null | undefined) {
    window.location.replace(url as string)
    return true
  }

  function redirectWithSession(){
    const credential = JSON.parse(atob(getCookie('credential')));
    const token = JSON.parse(atob(credential.credential.access_token.split('.')[1]));
    const currentLanguage = localStorage.getItem("i18nextLng");

    return redirectTo(`${urlRedirect}?session_id=${token.session_state}&lan=${currentLanguage}`)
  }

  function handleV2Logout() {
    deleteCookie('credential');
    const url = window.location.href.replace('/logout', '');
    window.location.replace(url);
  }

  async function handleV2Login(token: string) {
    setLoginMode('v2');
    if (token) {
      // validate token expiration      
      if (!V2Logged.current) {
        V2Logged.current = true;
        const { status } = await apiAuth.post('/generic/sso/protected', 
        JSON.stringify({
          access_token: atob(token),
          regenerate_session: true
        })
        )
        if (status === 200) {
          return redirectWithSession();
        }
        deleteCookie('credential');
      }

    }
  }
  
  useEffect(() => {
    const token = getCookie("credential")
    const directAccessKey = params.get("token")

    if (params.get('v') == '2') {
      if (window.location.href.includes('/logout')) {
        handleV2Logout();
      } else {
        handleV2Login(token);
      }
    }

    if (params.get('v') == '1') {
      if (token) {
        origins.forEach((e: string) => {
          window.parent.postMessage(getCookie("credential"), e);          
        });
        deleteCookie("credential")
      } else {
        origins.forEach((e: string) => {
          window.parent.postMessage(null, e);
        });
      }
    }

    // Caso a url venha com o token, faz a validação para o acesso direto.
    if ( directAccessKey && params.get("realm") && params.get("to")) {
      sendLogin(directAccessKey, link.DIRECT_ACCESS);
    }
    
    getTips();
    
  }, [])

  async function getTips (){
    try {
      const surveyUrl = urlRedirect?.split('/').pop();
      if (!surveyUrl) {
        return;
      }

      const url = process.env.REACT_APP_MONITOR_URL+'api/internalForm/v2/tips/' + surveyUrl;
      const headers = { username: 'SSO' };

      const { data, status } = await axios.get(url, { headers });
      if (status === 200 && data) {
        const i18nValue = languageValueConverter(data.languageDefault);
        localStorage.setItem('i18nextLng', i18nValue);
        setDefaultLanguage(i18nValue);
        setEnableHandTalk((!['en', 'pt_BR'].includes(data.languageDefault) || data.enableHandTalk === false) ? false : true);

        setIsLoginByEmail(data.login_is_email);
        setLanguagesData(data.languages);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }

  useEffect(() => {
    setTipsWithLanguage();
  }, [languagesData]);

  function setTipsWithLanguage() {
    
    var languageSelected = localStorage.getItem("i18nextLng");

    languageSelected = !languageSelected ? "pt-BR" : languageSelected
    languageSelected = languageSelected=="en-US" ? "en" : languageSelected;

    const findLanguage = (lang: string): LanguageItem | undefined => {
      return languagesData ? languagesData.find((item: LanguageItem) => item.language === lang) : undefined;
    };

    const languageFinded = findLanguage(languageSelected);

    if(languageFinded != undefined){
      setLoginTips(languageFinded.login && languageFinded.login.title ? languageFinded.login.title : "");
      setPasswordTips(languageFinded.password && languageFinded.password.title ? languageFinded.password.title : "");
      setHelpTips(languageFinded.tips && languageFinded.tips.title ? languageFinded.tips.title : "");
    }

  }

  async function sendLogin(body: any, link: string){
    setIsSubmitting(true)
    const loginVersion = loginMode === 'v2' ? 2 : 1;
    await loginSubmit(body, loginVersion, link, email ? email : null)
      .then((response) => {
        
        setIsSubmitting(false)
        if ( response ){
          onSuccess()
        } else {
          resetForm()
          setErrorVisible(true)
          
        }
      })
      .catch((err: any) => console.log(err))
  }

  function handleRecover(email: string) {
    if (email) {
      setEmail(email);
      setIsFirstAccess(true);
    }
  }

  function showPass(event: any) {
    let img = event.target.offsetParent.offsetParent.childNodes[1].firstChild
    let input = event.target.offsetParent.offsetParent.childNodes[2]
    if (input.type === "password") {
      input.type = "text"
      img.src = iconEyeClosed
    } else {
      input.type = "password"
      img.src = iconEye
    }
  }

  function resetForm() {
    setIsActive(true)
    setIsSubmitting(false)
  }

  function onSuccess() {
    if (loginMode === 'v2') {
      return redirectWithSession();
    }

    redirectTo(urlRedirect)
  }

  async function onSuccessForm(login: string, pass: string) {
    await sendLogin({
      login: login, 
      password: pass, 
      applicationName: process.env.REACT_APP_APPLICATION_NAME
    }, link.LOGIN);

    return redirectTo(urlRedirect)
  }

  async function handleForm(e: React.SyntheticEvent) {
    e.preventDefault()
    setIsActive(false)
    setIsSubmitting(true)
    setErrorVisible(false)
    setMessage(false)
    setEmailError(false)
    setPasswordError(false)

    if (!email) {
      setEmailError(true)
      resetForm()
      return false
    } else if (!password) {
      setPasswordError(true)
      resetForm()
      return false
    }

    const body = {
      login: email,
      password: password,
      applicationName: process.env.REACT_APP_APPLICATION_NAME,
    }

    try {
      const loginVersion = loginMode === 'v2' ? 2 : 1;
      const response = await loginSubmit(body, loginVersion, link.LOGIN, email);

      if ((response && !response.data) || !response) {
        resetForm()
        setErrorVisible(true)
        return
      }

      if (response != null) {
        if (response.data.refresh_token){
          onSuccess();
        } else if (response.status === HTTP_STATUS.CREATED) {
          resetForm()
          setMessage(true)

        } else {
          resetForm()
          setErrorVisible(true)
        }
      }

    } catch (err) {
      resetForm()

      let error = err as AxiosError<any>

      if (
        error.response?.status === HTTP_STATUS.BAD_REQUEST && 
        error.response?.data?.includes('Account is not fully set up')
      ) {
          setPassword("")
          setIsFirstAccess(true)
          return false;
        } else if (error.response?.status === HTTP_STATUS.CREATED) {
        resetForm()
        setMessage(true)
      } else {
        resetForm()
        setErrorVisible(true)
      }
      console.log(error.response)
      return false
    }
  }
  function setLabel(title : string){
    let titleSemTag  = title.replace(/<[^>]*>/g, '');
    titleSemTag = titleSemTag.replace("\n", '');
    titleSemTag = titleSemTag.trim()
    if(titleSemTag === ""){
      return ""
    }else{
      return <div dangerouslySetInnerHTML={{ __html:title}}></div>
    }

  }

  return (
    <Stack
      maxW={"100vw"}

      maxH={"100vh"}
      direction={{ base: "column", md: "row" }}
      bg="#ffffff"
    >      
      <div style={{position: 'relative', left: 'calc(100% - 90px)', top: '-50px'}}>
        <LanguageSelector setTipsWithLanguage={setTipsWithLanguage}/>
      </div>
      <Container>
        {!!!isFirstAccess ? (
          <Form onSubmit={(e) => handleForm(e)}>
            {/* <Label htmlFor="inputEmail">E-mail</Label> */}
            <Tooltip
            hasArrow
            bg={"#e53e3e"}
            placement='top-start'
            label={setLabel(loginTips)}
            closeDelay={900}
            openDelay={500}> 
            <Input
              label={<Translator path="login.email.title" />}
              placeholder={t("login.email.helper")}
              type="text"
              icon={<img src={iconLogin} alt="icon e-mail" />}
              {...register("email")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
            />
            </Tooltip>

            {emailError && (
              <Text fontSize="14px" fontWeight={'normal'}>
                <Translator path="login.email.error.required" />
              </Text>
            )}

            <Tooltip
              hasArrow
              bg={"#e53e3e"}
              placement='top-start'
              label={setLabel(passwordTips)}
              closeDelay={900}
              openDelay={500}>  
              <Input
                type="password"
                placeholder={t("login.password.helper")}
                icon={<img src={iconPassword} alt="icon password" />}
                iconRight={
                  <img
                    onClick={(e) => showPass(e)}
                    src={iconEye}
                    alt="icon eye"
                  />
                }
                label={<Translator path="login.password.title" />}
                {...register("password")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
              />
              </Tooltip>

            {passwordError && (
              <Text fontSize="14px" fontWeight={'normal'}>
                <Translator path="login.password.error.required" />
              </Text>
            )}
            
            {isLoginByEmail && (
              <RecoverPassword onRecover={handleRecover} isFirstAccess={isFirstAccess} />
            )}
              
            <Button
              mt="30px"
              type="submit"
              w="100%"
              isLoading={isSubmitting}
              color="white"
              fontSize="16px"
              lineHeight={"19px"}
              borderRadius="10px"
              h="56px"
              backgroundColor={
                !active ? "#949494" : products.getProduct().borderColor
              }
              variant="solid"
              _hover={{
                opacity: "0.2",
              }}
            >
              <Translator path="login.submit" />
            </Button>

            {isErrorVisible && (
              <Text fontSize="14px" fontWeight={'normal'}>
                <Translator path="login.error.invalid" />
              </Text>
            )}

            {isMessageVisible && (
              <Text fontWeight={'normal'}>
                <Translator path="login.error.firstAccess" />
              </Text>
            )}
          </Form>
        ) : (
          <ResetForm email={email} onSuccess={onSuccessForm} />
        )}
        <Container >
          <Box paddingTop={20} padding={4} zIndex={1000}>
          {setLabel(helpTips)}
        </Box>
        </Container>
      </Container>
      {defaultLanguage && enableHandTalk && <HandTalk />}
    </Stack>
  );
}
