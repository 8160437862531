import {
  Image1,
  Image2,
  Image3Container,
  Image4Container,
} from "./visualIdentity.style";
import { Image } from "@chakra-ui/react";

interface Product {
  id: string;
  logo: string;
  style: string;
  color: string;
  borderColor: string;
}
const PRODUCTS = [
  {
    id: "3405520a-6c87-4032-94a3-e80c5e322528",
    logo: "gptw.png",
    style: "gptw",
    color: "#EF7B7B",
    borderColor: "#E60000",
  },
  {
    id: "e370de4d-1a66-48ea-813e-8c24c82b7ab5",
    logo: "jungle.png",
    style: "jungle",
    color: "#90AEDD",
    borderColor: "#0565FF",
  },
  {
    id: "babf268c-94ac-41ea-b1e9-d34ab3225743",
    logo: "greatpeople.png",
    style: "greatpeople",
    color: "#66002B",
    borderColor: "#FF0D6F",
  },
  {
    id: "d6b70213-e6ff-41cb-b30b-f99648338e08",
    logo: "consulting.png",
    style: "consulting",
    color: "#F18B92",
    borderColor: "#FE1628",
  },
  {
    id: "41b62120-8357-496d-97f1-92292c3232e5",
    logo: "geracao_social.png",
    style: "geracao_social",
    color: "#8AD657",
    borderColor: "#0173BC",
  },
];

const getParamId = () => {
  const params = new URLSearchParams(window ? window.location.search : {});
  let productId = params.get("pid")
    ? params.get("pid")
    : "3405520a-6c87-4032-94a3-e80c5e322528";
  return productId;
};

export const products = {
  getProduct: (): Product => {
    const id = getParamId();
    const prod = PRODUCTS.filter((e) => e.id === id)[0];
    return prod;
  },
};

export function VisualIdentity() {
  return (
    <>
      <Image
        style={{ zIndex: 1 }}
        src={`/logos/${products.getProduct().logo}`}
        {...(products.getProduct().style === "geracao_social" && {
          maxW: "110px",
        })}
      ></Image>
      <Image1 style={{ backgroundColor: products.getProduct().color }}></Image1>
      <Image2
        style={{ borderColor: products.getProduct().borderColor }}
      ></Image2>
      <Image3Container>
        <svg
          width="800"
          height="620"
          viewBox="0 0 702 459"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M519.307 71.8064C641.999 -57.8006 1105.34 -23.2613 1110.89 274.94C1116.43 573.142 865.354 525.822 631.046 620.432C396.737 715.042 5.54858 668.718 0.00105573 370.517C-0.000988447 80.7356 269.661 335.524 519.307 71.8064Z"
            fill={products.getProduct().color}
          />
        </svg>
      </Image3Container>
      <Image4Container>
        <svg
          width="305"
          height="419"
          viewBox="0 0 305 419"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M305 2.50031C305 2.50031 266.5 57.0003 231.5 99.5003C196.5 142 155.501 165 155.501 165C155.501 165 73.4999 213.837 19.9999 251.5C-33.5001 289.164 72.4999 422.001 72.4999 422.001"
            stroke={products.getProduct().borderColor}
            stroke-width="8"
          />
        </svg>
      </Image4Container>
    </>
  );
}
