import styled from "styled-components";

export const Image1 = styled.div`
  position: absolute;
  width: 800px;
  height: 700px;
  left: -300px;
  top: -340px;
  margin: 0;
  border-radius:70%;  
`

export const Image2 = styled.div`
  box-sizing: border-box;
  border-radius: 10% 45% 45% 10%;
  position: absolute;
  width: 650.02px;
  height: 350.62px;
  left: -300.5px;
  top: 60px;
  transform: rotate(22.14deg);
  border: 8px solid;
`
export const Image3Container = styled.div`
    position: absolute;
    left: calc(100vw - 700px);
    top: calc(100vh - 500px);
`

export const Image4Container = styled.div`
    position: absolute;
    left: calc(100vw - 300px);
    top: calc(100vh - 400px);
`
  
export const Image3 = styled.div`
  width: 653px;
  height: 1191px;
  left: 858px;
  top: 1349px;

  background: #EF7B7B;
  transform: matrix(-0.02, -1.08, 0.87, -0.35, 0, 0);
`
