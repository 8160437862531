import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import {Login} from "./pages";
import GlobalStyle from "./styles/global"
import { ChakraProvider } from '@chakra-ui/react'

import { Suspense } from 'react';

import { VisualIdentity } from './components/visualIdentity/visualIdentity'

function App() {
  return (
    <ChakraProvider>
      <Suspense fallback={<></>}>
        <Router>
          <VisualIdentity></VisualIdentity>
        
          <GlobalStyle />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/*" element={<Login />} />
          </Routes>
        </Router>
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
