import React from 'react';
import { Helmet } from 'react-helmet';


const HandTalk = () => {
  return (
    <Helmet>
      <script type='text/javascript' src='/ht.js' ></script>
    </Helmet>
  );
};

export default HandTalk;