import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Form } from "./style";
import iconLogin from "../../assets/images/icon-login-email.svg";
import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeClosed from "../../assets/images/icon-eyeclosed.svg";
import iconPassword from "../../assets/images/icon-password.svg";
import { Input, Translator } from "../../components";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { products } from "../../components/visualIdentity/visualIdentity";
import apiAuth from "../../services/api";
import { link, loginSubmit } from "./login-submit";

export const ResetForm = ({ email, onSuccess }: any) => {
  const params = new URLSearchParams(window ? window.location.search : {});

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isErrorVisible, setErrorVisible] = useState(false);
  const [match, setMatch] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (password === confirmPassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [password, confirmPassword]);

  function showPass(event: any) {
    let img = event.target.offsetParent.offsetParent.childNodes[1].firstChild;
    let input = event.target.offsetParent.offsetParent.childNodes[2];
    if (input.type === "password") {
      input.type = "text";
      img.src = iconEyeClosed;
    } else {
      input.type = "password";
      img.src = iconEye;
    }
  }

  async function handleResetForm() {
    if (!!!match || !!!password) return;

    const realm = params.get("realm");
    try {
      const response = await apiAuth.post(`/generic/reset?realm=${realm}`, {
        login: email,
        password: password,
      });

      if (response.status !== 200) {
        reset();
        setErrorVisible(true);
        return;
      } else {
        onSuccess(email, password);
      }

      // const body = {
      //   login: email,
      //   password: password,
      //   applicationName: process.env.REACT_APP_APPLICATION_NAME,
      // };
      // const loginVersion = 1;

      // const responseLogin = await loginSubmit(body, loginVersion, link.LOGIN, email);

      // if ((responseLogin && !responseLogin.data) || !responseLogin) {
      //   reset();
      //   setErrorVisible(true);
      //   return;
      // }

      // if (responseLogin != null && responseLogin.data.refresh_token) {
      //   onSuccess();
      // }

    
    } catch (error: any) {
      console.error("Erro ao resetar senha.", error?.response);

      reset();
      setErrorVisible(true);
      setPassword("");
      setConfirmPassword("");
    }
  }

  return (
    <Box marginTop={"-108px"} display="contents">
      <Form onSubmit={handleSubmit(handleResetForm)}>
        <Text fontSize="24px" fontWeight="300" marginBottom="10px">
          <Translator path={"updatePassword.title"} />
        </Text>

        <Text
          fontSize={"12px"}
          marginBottom="20px"
          borderColor={products.getProduct().borderColor}
          borderWidth={"1px"}
          padding={"11px"}
          w="100%"
          textAlign="center"
        >
          <Translator path={"updatePassword.subtitle"} />
        </Text>
        <Input
          defaultValue=""
          type="password"
          label={<Translator path={"updatePassword.password.title"} />}
          placeholder=""
          icon={<img src={iconPassword} alt="icon password" />}
          iconRight={
            <img onClick={(e) => showPass(e)} src={iconEye} alt="icon eye" />
          }
          {...register("password")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
            setErrorVisible(false);
          }}
        />

        <Input
          defaultValue=""
          type="password"
          label={<Translator path={"updatePassword.confirmPassword.title"} />}
          placeholder=""
          icon={<img src={iconPassword} alt="icon confirm password" />}
          iconRight={
            <img onClick={(e) => showPass(e)} src={iconEye} alt="icon eye" />
          }
          {...register("confirmPassword")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(e.target.value);
            setErrorVisible(false);
          }}
        />

        <Flex alignSelf={"normal"}>
          {!!!match && (
            <Text
              position="absolute"
              left={"-50%"}
              right="-50%"
              textAlign={"center"}
              fontSize="14px"
            >
              <Translator path={"updatePassword.submit.error.match"} />
            </Text>
          )}

          {isErrorVisible && (
            <Text
              position="absolute"
              left={"-50%"}
              right="-50%"
              textAlign={"center"}
              fontSize="14px"
            >
              <Translator path={"updatePassword.submit.error.unknown"} />
            </Text>
          )}

          <Button
            mt="30px"
            type="submit"
            w="100%"
            isLoading={isSubmitting}
            color="white"
            fontSize="16px"
            lineHeight={"19px"}
            borderRadius="10px"
            h="56px"
            backgroundColor={
              !errors ? "#949494" : products.getProduct().borderColor
            }
            variant="solid"
            _hover={{
              opacity: "0.2",
            }}
          >
            <Translator path={"updatePassword.submit.title"} />
          </Button>
        </Flex>
      </Form>
    </Box>
  );
};
