import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import iconLogin from "../../../assets/images/icon-login-email.svg"
import { Input, Translator } from "../../../components";
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import apiAuth from '../../../services/api';
import { products } from '../../visualIdentity/visualIdentity';


interface IRecoverPasswordProps {
  onRecover: Function
  isFirstAccess: boolean
}

const RecoverPassword = (props: IRecoverPasswordProps) => {
  const [redirectUrl, setRedirectUrl] = useState<string | null | undefined>('');
  const [recoverUrl, setRecoverUrl] = useState<string | null | undefined>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [realm, setRealm] = useState<string>('');
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const params = new URLSearchParams(window ? window.location.search : {});
    const location = window.location.href;
    const host = window.location.host;
    const urlSplited = location.split(host);
    let realm = params.get("realm");
    setRealm(realm!);

    const urlRedirect = location.replace('/recover','').split('&key=')[0];
    setRedirectUrl(urlRedirect);

    if (location.includes('/recover') && !props.isFirstAccess) {
      return handleRecover()
    }
    
    const urlRecover = `${urlSplited[0]+host}/recover${urlSplited[1]}`;
    setRecoverUrl(urlRecover);    

  }, [redirectUrl, props]);

  const showToast = (type: 'success' | 'error', message: string) => {
    const id = 'recover';
    if (!toast.isActive(id)) {
      return toast({
        id,
        position: 'top',
        title: message,
        status: type,
        duration: 5000,
        isClosable: true
      })
    }
  }

  const handleRecover = () => {
    const params = new URLSearchParams(window ? window.location.search : {});
    apiAuth
      .get(`/generic/reset/${params.get('key')}`)
      .then((res) => {
        if (res.status === 200 && res.data) {
          props.onRecover(res.data);

        } else {
          showToast('error', t("updatePassword.recover.error"));
          window.location.href = window.location.href.replace('/recover','').split('&key=')[0];
        }
      })
      .catch((err) => {
        showToast('error', t("updatePassword.recover.error"));
        window.location.href = window.location.href.replace('/recover','').split('&key=')[0];      
      });
  }

  
  const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    
    apiAuth.post(`/generic/recover/${realm}?redirectUrl=${btoa(JSON.stringify(recoverUrl))}`, email)
      .then((res) => {

        if (res.status === 200) {
          showToast('success', t("updatePassword.recover.success"));
        }
        setLoading(false);
        setEmail('');
        onClose();

      }).catch((err) => {
        showToast('error', t("updatePassword.recover.error"));
        console.error(err);
        setLoading(false);
        setEmail('');
        onClose();
      })    
  }

  if (!props.isFirstAccess) {
    return (
      <>
        <Button
          w="154px"
          h="56px"
          marginLeft="auto"
          color={products.getProduct().borderColor}
          fontSize="14px"
          variant="link"
          lineHeight={"16px"}
          fontWeight="600"
          onClick={onOpen}
          _hover={{
            opacity: "0.6",
          }}
        >
          <Translator path="updatePassword.recover.text" />
        </Button>
        <Modal isOpen={isOpen} size={'md'} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader><Translator path="updatePassword.recover.title"/></ModalHeader>
            <ModalCloseButton />
            <form onSubmit={(e) => handleForm(e)}>
            
              <ModalBody>
                <Input
                  label={<Translator path="updatePassword.recover.email.title" />}
                  placeholder={t("updatePassword.recover.email.helper")}
                  type="email"
                  value={email}
                  icon={<img src={iconLogin} alt="icon e-mail" />}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                />
  
                {emailError && (
                  <Text fontSize="12px" textAlign={'center'} color="">
                    <Translator path="updatePassword.recover.email.error.required" />
                  </Text>
                )}
  
              </ModalBody>
  
              <ModalFooter>
                <Button 
                  type="submit"
                  w="100%"
                  isLoading={loading}
                  color="white"
                  fontSize="16px"
                  lineHeight={"19px"}
                  borderRadius="10px"
                  h="56px"
                  backgroundColor={products.getProduct().borderColor}
                  variant="solid"
                  _hover={{
                    opacity: "0.2",
                  }}
                  >
                  <Translator path='updatePassword.recover.submit'/>
                </Button>
              </ModalFooter>
  
            </form>
          </ModalContent>
        </Modal>
      </>
    )
  } else {
    return <></>
  }
}

export default RecoverPassword;