import Cookies from 'universal-cookie';
const cookies = new Cookies();


// DEV_DOMAIN: const DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || ".domain.tld"
const DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || '.' + window.location.hostname.split('.').splice(1,).join('.')


function setCookie(key, value) {
    cookies.set(key, value, { path: '/', domain: DOMAIN });
}

function getCookie(key) {
    return cookies.get(key)
}

function deleteCookie(key) {
    console.log('removendo')
    return cookies.remove(key, { path: '/', domain: DOMAIN })
}
 

export { setCookie, getCookie, deleteCookie }