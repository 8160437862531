import { Flex, Image, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import iconSelected from "../../assets/images/icon-arrow-down.svg";

interface LanguageSelectorProps {
  setTipsWithLanguage: () => void;
}

const allLanguages = [
  {value: 'pt-BR', title: 'Português'},
  {value: 'es', title: 'Español'},
  {value: 'en-US', title: 'English'},
  {value: 'fr-FR', title: 'Français'},
  {value: 'it', title: 'Italiano'},
  {value: 'de', title: 'Deutsch'},
  {value: 'ja-JP', title: '日本語'},
  {value: 'zh-CN', title: '中文'},
  {value: 'th', title: 'ไทย'},
  {value: 'pl', title: 'Polski'},
  {value: 'vie', title: 'Tiếng việt'},
  {value: 'ar', title: 'عربي'},
  {value: 'ru', title: 'Русский'},
];

const LanguageSelector:React.FC<LanguageSelectorProps> = ({setTipsWithLanguage}) => {
  const { i18n } = useTranslation();

  const [systemLanguage, setSystemLanguage] = useState({value: 'pt-BR', title: 'Português'});

  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng");
    let lang = allLanguages.filter(l => l.value === currentLanguage);

    if (!!currentLanguage && lang.length > 0) {
      setSystemLanguage(lang[0]);
    } else {
      setSystemLanguage(allLanguages[0]);
    }
    setTipsWithLanguage();
  }, [setSystemLanguage]);

  const handleChangeLanguage = async (e: any) => {
    let newLanguage;
    if (!!e.target.value) {
      newLanguage = e.target.value;
    } else {
      newLanguage = "pt-BR";
    }

    localStorage.setItem("i18nextLng", newLanguage);
    i18n.changeLanguage(newLanguage);
    setSystemLanguage(newLanguage);
    setTipsWithLanguage();
  };

  return (
    <Flex
      ml={{ base: 0, md: 0 }}
      px={{ base: 2, md: 0 }}
      py={{ base: 0, md: 5 }}
      height="64px"
      _focus={{ border: "none" }}
    >
      <Select
        color="#292B30"
        fontSize="14px"
        fontWeight="400"
        lineHeight={"16.44px"}
        icon={<Image src={iconSelected} />}
        placeholder={systemLanguage.title}
        borderRadius="10px"
        background={"#F1F5FA"}
        boxSizing="border-box"
        width="110px"
        height="36px"
        position={"absolute"}
        border="none"
        onChange={(e) => handleChangeLanguage(e)}
        _focus={{
          border: "none",
        }}
        value={systemLanguage.value}
      >
        {allLanguages && allLanguages.map(lang => lang !== systemLanguage && <option value={lang.value}>{lang.title}</option>)}
      </Select>
    </Flex>
  );
};

export const languageValueConverter = (language: string) => {
  const monitorValues: { [key: string]: string } = {
    en: 'en-US', 
    ru: 'ru', 
    ar: 'ar', 
    th: 'th', 
    pl: 'pl', 
    fr_FR: 'fr-FR', 
    pt_BR: 'pt-BR',
    it: 'it', 
    es: 'es', 
    ja_JP: 'ja-JP', 
    zh_CN: 'zh-CN', 
    vie: 'vie', 
    de: 'de'
  }

  return monitorValues[language];
}

export default LanguageSelector;
