import { setCookie } from "../../common/cookies";
import apiAuth from "../../services/api";

enum link {
  LOGIN = "login",
  DIRECT_ACCESS = "direct-access",
}

async function loginSubmit(data: any, loginVersion: number, type: string, user_email: string | null) {
  const params = new URLSearchParams(window ? window.location.search : {});
  let response = null;

  const realm = params.get("realm");

  response = await apiAuth.post(`/generic/${type}?v=${loginVersion}&realm=${realm}`, data);

  if (!response.data) {
    return null;
  }

  // Caso o login seja do tipo direto
  if (type === link.DIRECT_ACCESS) {
    user_email = response.data.user_email;
    response.data = response.data.access;
  }

  if (response.data.refresh_token) {
    const credentials = {
      credential: {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        user: {
          email: user_email,
          name: response.data.user_name,
          permissions: response.data.permissions,
          isGptw: response.data.isGptw,
        },
        permissions: response.data.permissions,
        roles: response.data.roles,
      },
    };

    // TODO: btoa() deprecado
    setCookie("credential", btoa(JSON.stringify(credentials)));
  }

  return response;
}

export { loginSubmit, link };
